
.jodit_container {
    width: 720px;
    margin-top: 24px;
    margin-bottom: 24px;
}
.jodit_container {

    .jodit_workplace {

        .jodit_wysiwyg {

            font-size: 20px;
            font-family: "PT Sans";
            color: #333;
            background-color: #fff;

            h1, h2, h3, h4, h5, h6 {
                font-family: "PT Sans";
            }
            h1 {
                font-size: 2em;
                margin: 0.67em 0;
            }

            h2 {
                display: block;
                font-size: 1.5em;
                margin-block-start: 0.83em;
                margin-block-end: 0.83em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                font-weight: bold;
            }

            p {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }
            ol {
                display: block;
                list-style-type: decimal;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }

            ul {
                display: block;
                list-style-type: disc;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }
            b {
                font-weight: bold;
            }
            strong {
                font-weight: bold;
            }

            a {
                color: #052B33;
                transition: color 300ms ease-out;
                cursor: pointer;
            }
            a:hover, a:focus {
                color: #0c6679;
            }
            a {
                color: #052B33;
                transition: color 300ms ease-out;
                cursor: pointer;
            }
            a:hover, a:focus {
                color: #0c6679;
            }
            em {
                font-style: italic;
            }
        
        }

    }
}