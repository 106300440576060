@mixin ellipsis-block($font-size, $line-height, $lines-to-show, $max-width) {
	display: block;
	display: -webkit-box;
	max-width: $max-width*1px;
	height: $font-size*$line-height*$lines-to-show; 
	margin: 0 auto;
	font-size: $font-size;
	line-height: $line-height;
		-webkit-line-clamp: $lines-to-show;
		-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}


.response-list-item {
    
    cursor: pointer;
    position: relative;
    width: 330px;
    
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 18px;    
    margin-bottom: 18px;
    
    border-radius: 4px;
    border: solid 1px;
    border-color: #ddd;
    box-shadow: 0px 3px 7px #aaa;
    
       padding-top: 15px;
       padding-bottom: 15px;
       padding-left: 20px;
       padding-right: 20px;
       
    background-color: white;
         
    .response-list-item__top {
        width: 100%;
        min-height: 130px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        
        .response-list-item__image {
            margin-top: 5px;
            
            img {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                
            }
            .response-list-item__no-image {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: none;
                background-color: #a0a0a0; 

                .iconic-person-female-body,
                .iconic-person-male-body,
                .iconic-person-body  {
                    fill: #d0d0d0;
                }
                .iconic-person-female-head,
                .iconic-person-male-head,
                .iconic-person-head {
                    fill: #e9e9e9;
                }
            }
            .signature {
                width: 95px;
                margin-top: 5px;
                font-size: 62%;
                color: #333;	
            }
        }



        .response-list-item__content {
            min-width: 185px;
            height: 120px;
            margin-top: 5px;
            color: #333;
            
            .response-list-item__topic {
                font-size: 15px;
                font-weight: bold;
                max-width: 170px;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 15px;
                height: 15px;
                white-space: nowrap;
                color: #63a9be;
                margin-bottom: 3px;
            }
            

            .response-list-item__title {
                font-weight: bold;
                @include ellipsis-block(18px, 1.2, 2, 185);
            }
        
            .response-list-item__slogan_with_title {
                @include ellipsis-block(18px, 1.2, 3, 185);
            }
        
            .response-list-item__slogan_only {
                @include ellipsis-block(18px, 1.2, 5, 185);
            }
        }
    }
    .response-list-item__owner_logo {	
        .response-list-item__owner_logo_text {
            font-size: 14px;
            color: #63a9be; 
            margin-bottom: 0;
        }
    }	
    .response-list-item__footer {
        /* display: block; */
        clear: both;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        
        .status {
            min-width: 135px; 
            margin-top: 10px;
            font-size: 15px;
            
            .published {
                color: #d53d4c; /* #DE6773 */
                margin-bottom: 5px;
            }
            .timestamp {
                .text {
                    color: #63a9be;							
                }
            }
                
        }
        .read-more-button {
            margin-top: 10px;
            white-space: nowrap;
        }
    }
}

