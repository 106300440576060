.admin-button {
    .admin-button__icon {
        vertical-align: middle;
        padding-bottom: 2px;
    }
}
.admin-field {
    .admin-button {
        margin-top: 10px;
    }
}
.footer {
    margin-top: 15px;
}
.name-list::after {
    content: ", ";
}
.name-list:last-child::after {
    content: none;
}

.admin-button.admin-button--primary.bg-primary {
    background-color: #de6773;
    color: #fff;
}

.add-widget {
    margin: 1em;
    margin-left: -6px;
    padding-left: 1em;
    font-size: 14px;
}

.nav-status,
.nav-toggle {
    display: none!important;
}

aside.admin__sidebar {
    background-color: #fff;
    border-right: 1px solid #ddd;
}

aside.admin__sidebar nav.nav-action {
    margin-bottom: 0;
}

aside.admin__sidebar button.admin-button {
    margin-left: 12px;
    margin-top: 12px;
}

aside.admin__sidebar > .nav-menu {
    position: relative;
    padding-top: 24px;
    overflow-y: auto;

    li.nav-menu__item {
        font-size: 125%;

        a {
            color: #008FAD;
        }
    }
}

.response-item {
    /*font-size: 125%;*/

    .response-item-media {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 10px;
    
        .media-video,
        .media-audio,
        .media-document,
        .media-image {
            background-color: rgb(231, 236, 237);
            box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.12), 0 1.5px 3px rgba(0, 0, 0, 0.06);
            margin-bottom: 20px;
            margin-right: 20px;
            padding: 20px;

            img {
                cursor: pointer;
            }
        }
        .media-document {

        }
    }

    label.response-item-label {
        font-size: 75%;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .response-item-text {
        font-family: 'Noto Serif';
        margin-bottom: 20px;
    }

    .response-item-html {
        background-color: #fcfcfc;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        padding: 20px;

        h1 {
            font-size: 150%;
            font-weight: bold;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 125%;
            font-weight: bold;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 100%;
            font-weight: bold;
            margin-bottom: 10px;
        }

        p {
            font-family: 'Noto Serif';
            margin-bottom: 10px;
        }
    }
}

.media-text {
    font-family: 'Noto Serif';
    margin-bottom: 10px;
    margin-top: 10px;
}

.media-metadata-item {
    margin-right: 10px;

    a img {
        margin-bottom: -2px;
    }
}

h1.modal-title {
    margin-bottom: 10px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.response-view,
.student-view {
    background-color: #fff;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.12), 0 1.5px 3px rgba(0, 0, 0, 0.06);
    margin: 20px;
    padding: 20px;

    h1 {
        font-size: 200%;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .response-section,
    .student-section {
        border-bottom: 1px solid #ddd;
        padding: 20px 0;
        h2 {
            // font-size: 125%;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    .response-section:last-child,
    .student-section:last-child {
        border-bottom: none;
    }

    .response-view-close,
    .student-view-close {
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .student-view-controls {
        position: absolute;
        top: 30px;
        right: 50%;
    }
    .admin-widget {
        background-color: rgb(231, 236, 237);
        width: 280px;
        p.reponse-slogan {
            font-family: 'Noto Serif';
            margin-top: 10px;
        }
    }
}
.button-group {
    
    button {
        margin-right: 16px;
        margin-bottom: 16px;
    }
}


@media print {
    // This is brute force - sledge hammer style css in order to
    // make a printable list of students in a school class.
    // no flex and no hidden overflow is key
    * { overflow: visible !important; } 
    // * { float: none !important; } 
    *  { box-shadow: none !important; }

    .admin-layout--flex {
        display: block !important;
    }

    .no-print {
        display: none;
    }
    .MuiAppBar-root {
        display: none !important;
    }
    
    header.admin-editor__header {
        display: none;
    }
    
    .admin__body {
        top: 0px
    }
    .admin-editor__body {
        top: 0px;
    }
    @page {
        size: A4 landscape;
    }
    .student {
        float: left;
        border: solid 1px;
        border-style: dashed;
        .admin-widget {
            width: 22em;
            padding: 0px;
        }
        .admin-layout--flex {
            .admin-widget {
                h1 {
                    font-size: 100%;
                }
            } 
        }
    }
    .end-block-of-students {
        break-after: page;
    }

    h1.student-header {
        font-size: 100% !important;
    }
}



@media screen {
    .only-print {
        display: none;
    }
}



.memory-indicator-button {
    margin-left: 10px;
}
.memories-needs-approval {
    color: #de6773;
}
.bg-ok {
    background-color:#00994D;
}

.bg-yellow {
    background-color:#ffdf86;
    color: #212121
}


.response-section {
    .status {
        margin-bottom: 10px;
        .allert-text {
            color: #de6773;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    .actions {
        .admin-buttongroup--inline {
            margin-bottom: 10px;
        }
    }
}

h2.admin-section__title {
    text-align: left;
    font-weight: bold;
    font-size: 100%;
    margin-top: 24px;
}

.mailto-link {
    display: flex;
    text-decoration: none;
}

.mailto-text {
    margin-left: 10px;
    line-height: 26px;
}

.admin-section {
    .admin-layout {
        margin-left: -10px;
    }
}


.admin-section__body {
    margin-left: 10px;
}

.admin-section__header {
    margin-left: 10px;
}

table.statistics {
    width: 400px;
    td {
        padding: 4px;
    }
}


